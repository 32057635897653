const codes = [
  {
    "code": "93",
    "country": "Afghanistan (+93)"
  },
  {
    "code": "355",
    "country": "Albania (+355)"
  },
  {
    "code": "213",
    "country": "Algeria (+213)"
  },
  {
    "code": "1684",
    "country": "American Samoa (+1684)"
  },
  {
    "code": "376",
    "country": "Andorra (+376)"
  },
  {
    "code": "244",
    "country": "Angola (+244)"
  },
  {
    "code": "1264",
    "country": "Anguilla (+1264)"
  },
  {
    "code": "1268",
    "country": "Antigua and Barbuda (+1268)"
  },
  {
    "code": "54",
    "country": "Argentina (+54)"
  },
  {
    "code": "374",
    "country": "Armenia (+374)"
  },
  {
    "code": "297",
    "country": "Aruba (+297)"
  },
  {
    "code": "247",
    "country": "Ascension (+247)"
  },
  {
    "code": "61",
    "country": "Australia (+61)"
  },
  {
    "code": "6721",
    "country": "Australian Antarctic Territory (+6721)"
  },
  {
    "code": "672",
    "country": "Australian External Territories (+672)"
  },
  {
    "code": "43",
    "country": "Austria (+43)"
  },
  {
    "code": "994",
    "country": "Azerbaijan (+994)"
  },
  {
    "code": "1242",
    "country": "Bahamas (+1242)"
  },
  {
    "code": "973",
    "country": "Bahrain (+973)"
  },
  {
    "code": "880",
    "country": "Bangladesh (+880)"
  },
  {
    "code": "1246",
    "country": "Barbados (+1246)"
  },
  {
    "code": "375",
    "country": "Belarus (+375)"
  },
  {
    "code": "32",
    "country": "Belgium (+32)"
  },
  {
    "code": "501",
    "country": "Belize (+501)"
  },
  {
    "code": "229",
    "country": "Benin (+229)"
  },
  {
    "code": "1441",
    "country": "Bermuda (+1441)"
  },
  {
    "code": "975",
    "country": "Bhutan (+975)"
  },
  {
    "code": "591",
    "country": "Bolivia (+591)"
  },
  {
    "code": "5997",
    "country": "Bonaire (+5997)"
  },
  {
    "code": "387",
    "country": "Bosnia and Herzegovina (+387)"
  },
  {
    "code": "267",
    "country": "Botswana (+267)"
  },
  {
    "code": "55",
    "country": "Brazil (+55)"
  },
  {
    "code": "246",
    "country": "British Indian Ocean Territory (+246)"
  },
  {
    "code": "1284",
    "country": "British Virgin Islands (+1284)"
  },
  {
    "code": "673",
    "country": "Brunei Darussalam (+673)"
  },
  {
    "code": "359",
    "country": "Bulgaria (+359)"
  },
  {
    "code": "226",
    "country": "Burkina Faso (+226)"
  },
  {
    "code": "257",
    "country": "Burundi (+257)"
  },
  {
    "code": "855",
    "country": "Cambodia (+855)"
  },
  {
    "code": "237",
    "country": "Cameroon (+237)"
  },
  {
    "code": "1",
    "country": "Canada (+1)"
  },
  {
    "code": "238",
    "country": "Cape Verde (+238)"
  },
  {
    "code": "599",
    "country": "Caribbean Netherlands (+599)"
  },
  {
    "code": "1345",
    "country": "Cayman Islands (+1345)"
  },
  {
    "code": "236",
    "country": "Central African Republic (+236)"
  },
  {
    "code": "235",
    "country": "Chad (+235)"
  },
  {
    "code": "56",
    "country": "Chile (incl. Easter Island) (+56)"
  },
  {
    "code": "86",
    "country": "China (+86)"
  },
  {
    "code": "6189164",
    "country": "Christmas Island (+6189164)"
  },
  {
    "code": "6189162",
    "country": "Cocos (Keeling) Islands (+6189162)"
  },
  {
    "code": "57",
    "country": "Colombia (+57)"
  },
  {
    "code": "269",
    "country": "Comoros (+269)"
  },
  {
    "code": "242",
    "country": "Congo (+242)"
  },
  {
    "code": "243",
    "country": "Congo, Democratic Republic of the (Zaire) (+243)"
  },
  {
    "code": "682",
    "country": "Cook Islands (+682)"
  },
  {
    "code": "506",
    "country": "Costa Rica (+506)"
  },
  {
    "code": "385",
    "country": "Croatia (+385)"
  },
  {
    "code": "53",
    "country": "Cuba (+53)"
  },
  {
    "code": "5999",
    "country": "Curaçao (+5999)"
  },
  {
    "code": "357",
    "country": "Cyprus (+357)"
  },
  {
    "code": "420",
    "country": "Czech Republic (+420)"
  },
  {
    "code": "45",
    "country": "Denmark (+45)"
  },
  {
    "code": "253",
    "country": "Djibouti (+253)"
  },
  {
    "code": "1767",
    "country": "Dominica (+1767)"
  },
  {
    "code": "18",
    "country": "Dominican Republic (+18)"
  },
  {
    "code": "670",
    "country": "East Timor (+670)"
  },
  {
    "code": "593",
    "country": "Ecuador (+593)"
  },
  {
    "code": "20",
    "country": "Egypt (+20)"
  },
  {
    "code": "503",
    "country": "El Salvador (+503)"
  },
  {
    "code": "240",
    "country": "Equatorial Guinea (+240)"
  },
  {
    "code": "291",
    "country": "Eritrea (+291)"
  },
  {
    "code": "372",
    "country": "Estonia (+372)"
  },
  {
    "code": "251",
    "country": "Ethiopia (+251)"
  },
  {
    "code": "500",
    "country": "Falkland Islands or South Georgia & the South Sandwich Islands (+500)"
  },
  {
    "code": "298",
    "country": "Faroe Islands (+298)"
  },
  {
    "code": "691",
    "country": "Federated States of Micronesia (+691)"
  },
  {
    "code": "679",
    "country": "Fiji (+679)"
  },
  {
    "code": "358",
    "country": "Finland (+358)"
  },
  {
    "code": "33",
    "country": "France (+33)"
  },
  {
    "code": "594",
    "country": "French Guiana (+594)"
  },
  {
    "code": "689",
    "country": "French Polynesia (+689)"
  },
  {
    "code": "241",
    "country": "Gabon (+241)"
  },
  {
    "code": "220",
    "country": "Gambia (+220)"
  },
  {
    "code": "995",
    "country": "Georgia (+995)"
  },
  {
    "code": "49",
    "country": "Germany (+49)"
  },
  {
    "code": "233",
    "country": "Ghana (+233)"
  },
  {
    "code": "350",
    "country": "Gibraltar (+350)"
  },
  {
    "code": "30",
    "country": "Greece (+30)"
  },
  {
    "code": "299",
    "country": "Greenland (+299)"
  },
  {
    "code": "1473",
    "country": "Grenada (+1473)"
  },
  {
    "code": "590",
    "country": "Guadeloupe, Saint Barthélemy and Saint Martin (+590)"
  },
  {
    "code": "1671",
    "country": "Guam (+1671)"
  },
  {
    "code": "502",
    "country": "Guatemala (+502)"
  },
  {
    "code": " 44",
    "country": "Guernsey (+44)"
  },
  {
    "code": "224",
    "country": "Guinea (+224)"
  },
  {
    "code": "245",
    "country": "Guinea-Bissau (+245)"
  },
  {
    "code": "592",
    "country": "Guyana (+592)"
  },
  {
    "code": "509",
    "country": "Haiti (+509)"
  },
  {
    "code": "504",
    "country": "Honduras (+504)"
  },
  {
    "code": "852",
    "country": "Hong Kong (+852)"
  },
  {
    "code": "36",
    "country": "Hungary (+36)"
  },
  {
    "code": "354",
    "country": "Iceland (+354)"
  },
  {
    "code": "91",
    "country": "India (+91)"
  },
  {
    "code": "62",
    "country": "Indonesia (+62)"
  },
  {
    "code": "98",
    "country": "Iran (+98)"
  },
  {
    "code": "964",
    "country": "Iraq (+964)"
  },
  {
    "code": "353",
    "country": "Ireland (+353)"
  },
  {
    "code": "44 ",
    "country": "Isle of Man (+44)"
  },
  {
    "code": "972",
    "country": "Israel (+972)"
  },
  {
    "code": "39",
    "country": "Italy (+39)"
  },
  {
    "code": "225",
    "country": "Ivory Coast (+225)"
  },
  {
    "code": "1876",
    "country": "Jamaica (+1876)"
  },
  {
    "code": "4779",
    "country": "Jan Mayen / Svalbard (Norway) (+4779)"
  },
  {
    "code": "81",
    "country": "Japan (+81)"
  },
  {
    "code": "441534",
    "country": "Jersey (+441534)"
  },
  {
    "code": "962",
    "country": "Jordan (+962)"
  },
  {
    "code": "77",
    "country": "Kazakhstan (+77)"
  },
  {
    "code": "254",
    "country": "Kenya (+254)"
  },
  {
    "code": "686",
    "country": "Kiribati (+686)"
  },
  {
    "code": "383",
    "country": "Kosovo (+383)"
  },
  {
    "code": "965",
    "country": "Kuwait (+965)"
  },
  {
    "code": "996",
    "country": "Kyrgyzstan (+996)"
  },
  {
    "code": "856",
    "country": "Laos (+856)"
  },
  {
    "code": "371",
    "country": "Latvia (+371)"
  },
  {
    "code": "961",
    "country": "Lebanon (+961)"
  },
  {
    "code": "266",
    "country": "Lesotho (+266)"
  },
  {
    "code": "231",
    "country": "Liberia (+231)"
  },
  {
    "code": "218",
    "country": "Libya (+218)"
  },
  {
    "code": "423",
    "country": "Liechtenstein (+423)"
  },
  {
    "code": "370",
    "country": "Lithuania (+370)"
  },
  {
    "code": "352",
    "country": "Luxembourg (+352)"
  },
  {
    "code": "853",
    "country": "Macau (+853)"
  },
  {
    "code": "389",
    "country": "Macedonia (+389)"
  },
  {
    "code": "261",
    "country": "Madagascar (+261)"
  },
  {
    "code": "265",
    "country": "Malawi (+265)"
  },
  {
    "code": "60",
    "country": "Malaysia (+60)"
  },
  {
    "code": "960",
    "country": "Maldives (+960)"
  },
  {
    "code": "223",
    "country": "Mali (+223)"
  },
  {
    "code": "356",
    "country": "Malta (+356)"
  },
  {
    "code": "692",
    "country": "Marshall Islands (+692)"
  },
  {
    "code": "596",
    "country": "Martinique (+596)"
  },
  {
    "code": "222",
    "country": "Mauritania (+222)"
  },
  {
    "code": "230",
    "country": "Mauritius (+230)"
  },
  {
    "code": " 262",
    "country": "Mayotte (+262)"
  },
  {
    "code": "52",
    "country": "Mexico (+52)"
  },
  {
    "code": "1808",
    "country": "Midway Island and Wake Island, USA (+1808)"
  },
  {
    "code": "373",
    "country": "Moldova (+373)"
  },
  {
    "code": "377",
    "country": "Monaco (+377)"
  },
  {
    "code": "976",
    "country": "Mongolia (+976)"
  },
  {
    "code": "382",
    "country": "Montenegro (+382)"
  },
  {
    "code": "1664",
    "country": "Montserrat (+1664)"
  },
  {
    "code": "212",
    "country": "Morocco (+212)"
  },
  {
    "code": "258",
    "country": "Mozambique (+258)"
  },
  {
    "code": "95",
    "country": "Myanmar (+95)"
  },
  {
    "code": "264",
    "country": "Namibia (+264)"
  },
  {
    "code": "674",
    "country": "Nauru (+674)"
  },
  {
    "code": "977",
    "country": "Nepal (+977)"
  },
  {
    "code": "31",
    "country": "Netherlands (+31)"
  },
  {
    "code": "687",
    "country": "New Caledonia (+687)"
  },
  {
    "code": "64",
    "country": "New Zealand (+64)"
  },
  {
    "code": "505",
    "country": "Nicaragua (+505)"
  },
  {
    "code": "227",
    "country": "Niger (+227)"
  },
  {
    "code": "234",
    "country": "Nigeria (+234)"
  },
  {
    "code": "683",
    "country": "Niue (+683)"
  },
  {
    "code": "6723",
    "country": "Norfolk Island (+6723)"
  },
  {
    "code": "850",
    "country": "North Korea (+850)"
  },
  {
    "code": "90392",
    "country": "Northern Cyprus (+90392)"
  },
  {
    "code": "4428",
    "country": "Northern Ireland (+4428)"
  },
  {
    "code": "1670",
    "country": "Northern Mariana Islands (+1670)"
  },
  {
    "code": "47",
    "country": "Norway (+47)"
  },
  {
    "code": "968",
    "country": "Oman (+968)"
  },
  {
    "code": "92",
    "country": "Pakistan (+92)"
  },
  {
    "code": "680",
    "country": "Palau (+680)"
  },
  {
    "code": "970",
    "country": "Palestine (+970)"
  },
  {
    "code": "507",
    "country": "Panama (+507)"
  },
  {
    "code": "675",
    "country": "Papua New Guinea (+675)"
  },
  {
    "code": "595",
    "country": "Paraguay (+595)"
  },
  {
    "code": "51",
    "country": "Peru (+51)"
  },
  {
    "code": "63",
    "country": "Philippines (+63)"
  },
  {
    "code": "48",
    "country": "Poland (+48)"
  },
  {
    "code": "351",
    "country": "Portugal (+351)"
  },
  {
    "code": "974",
    "country": "Qatar (+974)"
  },
  {
    "code": "40",
    "country": "Romania (+40)"
  },
  {
    "code": "7",
    "country": "Russia (+7)"
  },
  {
    "code": "250",
    "country": "Rwanda (+250)"
  },
  {
    "code": "262",
    "country": "Réunion (+262)"
  },
  {
    "code": "5994",
    "country": "Saba (+5994)"
  },
  {
    "code": "290",
    "country": "Saint Helena (+290)"
  },
  {
    "code": "1869",
    "country": "Saint Kitts and Nevis (+1869)"
  },
  {
    "code": "1758",
    "country": "Saint Lucia (+1758)"
  },
  {
    "code": "508",
    "country": "Saint Pierre and Miquelon (+508)"
  },
  {
    "code": "1784",
    "country": "Saint Vincent and the Grenadines (+1784)"
  },
  {
    "code": "685",
    "country": "Samoa (+685)"
  },
  {
    "code": "378",
    "country": "San Marino (+378)"
  },
  {
    "code": "966",
    "country": "Saudi Arabia (+966)"
  },
  {
    "code": "221",
    "country": "Senegal (+221)"
  },
  {
    "code": "381",
    "country": "Serbia (+381)"
  },
  {
    "code": "248",
    "country": "Seychelles (+248)"
  },
  {
    "code": "232",
    "country": "Sierra Leone (+232)"
  },
  {
    "code": "65",
    "country": "Singapore (+65)"
  },
  {
    "code": "5993",
    "country": "Sint Eustatius (+5993)"
  },
  {
    "code": "1721",
    "country": "Sint Maarten (Netherlands) (+1721)"
  },
  {
    "code": "421",
    "country": "Slovakia (+421)"
  },
  {
    "code": "386",
    "country": "Slovenia (+386)"
  },
  {
    "code": "677",
    "country": "Solomon Islands (+677)"
  },
  {
    "code": "252",
    "country": "Somalia (+252)"
  },
  {
    "code": "27",
    "country": "South Africa (+27)"
  },
  {
    "code": "82",
    "country": "South Korea (+82)"
  },
  {
    "code": "99534",
    "country": "South Ossetia (+99534)"
  },
  {
    "code": "211",
    "country": "South Sudan (+211)"
  },
  {
    "code": "34",
    "country": "Spain (+34)"
  },
  {
    "code": "94",
    "country": "Sri Lanka (+94)"
  },
  {
    "code": "249",
    "country": "Sudan (+249)"
  },
  {
    "code": "597",
    "country": "Suriname (+597)"
  },
  {
    "code": "268",
    "country": "Swaziland (+268)"
  },
  {
    "code": "46",
    "country": "Sweden (+46)"
  },
  {
    "code": "41",
    "country": "Switzerland (+41)"
  },
  {
    "code": "963",
    "country": "Syria (+963)"
  },
  {
    "code": "239",
    "country": "São Tomé and Príncipe (+239)"
  },
  {
    "code": "886",
    "country": "Taiwan (+886)"
  },
  {
    "code": "992",
    "country": "Tajikistan (+992)"
  },
  {
    "code": "255",
    "country": "Tanzania (+255)"
  },
  {
    "code": "66",
    "country": "Thailand (+66)"
  },
  {
    "code": "228",
    "country": "Togo (+228)"
  },
  {
    "code": "690",
    "country": "Tokelau (+690)"
  },
  {
    "code": "676",
    "country": "Tonga (+676)"
  },
  {
    "code": "1868",
    "country": "Trinidad and Tobago (+1868)"
  },
  {
    "code": "2908",
    "country": "Tristan da Cunha (+2908)"
  },
  {
    "code": "216",
    "country": "Tunisia (+216)"
  },
  {
    "code": "90",
    "country": "Turkey (+90)"
  },
  {
    "code": "993",
    "country": "Turkmenistan (+993)"
  },
  {
    "code": "1649",
    "country": "Turks and Caicos Islands (+1649)"
  },
  {
    "code": "688",
    "country": "Tuvalu (+688)"
  },
  {
    "code": "1340",
    "country": "US Virgin Islands (+1340)"
  },
  {
    "code": "256",
    "country": "Uganda (+256)"
  },
  {
    "code": "380",
    "country": "Ukraine (+380)"
  },
  {
    "code": "971",
    "country": "United Arab Emirates (+971)"
  },
  {
    "code": "44",
    "country": "United Kingdom (+44)"
  },
  {
    "code": " 1",
    "country": "United States (incl. Puerto Rico) (+1)"
  },
  {
    "code": "598",
    "country": "Uruguay (+598)"
  },
  {
    "code": "998",
    "country": "Uzbekistan (+998)"
  },
  {
    "code": "678",
    "country": "Vanuatu (+678)"
  },
  {
    "code": "3906698",
    "country": "Vatican City State (+3906698)"
  },
  {
    "code": "58",
    "country": "Venezuela (+58)"
  },
  {
    "code": "84",
    "country": "Vietnam (+84)"
  },
  {
    "code": "681",
    "country": "Wallis and Futuna (+681)"
  },
  {
    "code": "967",
    "country": "Yemen (+967)"
  },
  {
    "code": "260",
    "country": "Zambia (+260)"
  },
  {
    "code": "25524",
    "country": "Zanzibar (+25524)"
  },
  {
    "code": "263",
    "country": "Zimbabwe (+263)"
  },
  {
    "code": "35818",
    "country": "Åland Islands (+35818)"
  }
]

export default codes
