import React        from 'react'
import cx           from 'utils/classnames'
import {
    Container,
    Col,
    Row
}                   from 'react-bootstrap'
import Typography   from 'entrada-ui/Typography'
import BookContact  from 'components/BookContact'
import styles       from './styles.module.scss'


const Guarantee = (props) => {
    const {
        className
    } = props

    return (
        <div className={className}>
            <hr/>
            <Typography className='mb-0' variant="h4">
                Lowest Price Guarantee
            </Typography>
            <p>Find it cheaper? We'll refund the difference.</p>

            <Typography className='mb-0' variant="h4">
                Local support
            </Typography>
            <p>Get the answers you need, when you need them.</p>

            <Typography className='mb-0' variant="h4">
                Book Securely
            </Typography>
            <p>We use SSL encryption to keep your data secure</p>

            <hr/>

            <Typography className="mt-0 mb-3" variant="h2" component="h4" color="brand-color">
                Questions?
            </Typography>
            <BookContact big={true} />
        </div>
    )
}

export default Guarantee
