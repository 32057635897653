import React, { useEffect, useRef } from 'react'

const AutoSubmitForm = ({
  actionUrl,
  method = 'POST',
  enctype = 'multipart/form-data',
  params
}) => {
  const formRef = useRef(null)
  useEffect(() => {
    formRef.current.submit()
  }, [])

  return (
    <form ref={formRef} enctype={enctype} method={method} action={actionUrl}>
      {Object.keys(params).map(name => (
        <input
          type="hidden"
          name={name}
          value={params[name]}
        ></input>
      ))}
    </form>
  );
}

export default AutoSubmitForm
